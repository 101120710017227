'use strict'

angular.module('cb-printing', [])
.factory('PrintingService', function () {
    var PrintingService = {

        ReportForPreview: {}

    };
    return PrintingService;
})

//current keys that will be found in the ReportForPreview object:
//ReportForPreview.Company,
//ReportForPreview.OrderValue,
//ReportForPreview.Fitter,
//ReportForPreview.SalesPerson,
//ReportForPreview.Surveyed,
//ReportForPreview.HomePhone,
//ReportForPreview.Created,
//ReportForPreview.Value,
//ReportForPreview.Source,
//ReportForPreview.LeadDate,
//ReportForPreview.LeadSource,
//ReportForPreview.BlindUse,
//ReportForPreview.Issued,
//ReportForPreview.SoldDate,
//ReportForPreview.Completed,
//ReportForPreview.Salesman,
//ReportForPreview.FirstProduct,
//ReportForPreview.FirstSupplier,
//ReportForPreview.Area,
//ReportForPreview.Email,
//ReportForPreview.OrderDate,
//ReportForPreview.Installation,
//ReportForPreview.Completion,
//ReportForPreview.Paid,
//ReportForPreview.Phone,
//ReportForPreview.PaymentCompleted,
//ReportForPreview.JobComplete,
//ReportForPreview.Orders,
//ReportForPreview.SourceDetail,
//ReportForPreview.Enquiries,
//ReportForPreview.LeadStatus,
//ReportForPreview.LeadType,
//ReportForPreview.BlindType,
//ReportForPreview.BroucherSent,
//ReportForPreview.Appointed,
//ReportForPreview.Total,
//ReportForPreview.Rep,
//ReportForPreview.Delivered,
//ReportForPreview.LeadProvider,
//ReportForPreview.SupplierDelivery